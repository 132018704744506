import logo from './softa_logo.png';
import './App.css';
import React from 'react';
import PartyController from './PartyController';

class App extends React.Component {

  constructor(props) {
    super(props);
    if(localStorage.getItem('authToken') == null) {
      this.state = {isSignedIn: false}
    } else {
      this.state = {isSignedIn: true, authToken: localStorage.getItem('authToken'), partyCode: null, textFieldContents: ""}
    }
    this.windowObjectReference = null;
    this.openSpotifyAuthentication = this.openSpotifyAuthentication.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendJoinRequest = this.sendJoinRequest.bind(this);
    window.addEventListener("message", (event) => {
      if (event.origin !== "https://programmering2.farkasdev.com") return;
      this.state.isSignedIn = true;
      this.state.authToken = event.data;
      localStorage.setItem("authToken", event.data)
      this.windowObjectReference.close()
      window.location.reload()
    }, false);
  }

  async sendJoinRequest(code) {
    let formData = new FormData();
    formData.append('short', code);

    const response = await fetch("https://programmering2.farkasdev.com/party/join", {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Authorization': this.state.authToken
      },
      referrerPolicy: 'origin',
      body: formData
    });
    return response.json();
  }

  openSpotifyAuthentication() {
    if(this.windowObjectReference == null || this.windowObjectReference.closed) {
      this.windowObjectReference = window.open("https://programmering2.farkasdev.com/auth/authenticate", "OpenSpotifyAuth", "popup");
      console.log(this.windowObjectReference);
    } else {
      this.windowObjectReference.focus();
    }
  }

  handleCodeChange(event) {
    if(event.target.value.length > 6) return;
    this.setState({textFieldContents: event.target.value.toUpperCase()});
  }

  handleSubmit(event) {
    var partyCode = this.state.textFieldContents;
    this.sendJoinRequest(partyCode).then(onJoin => {
      console.log(onJoin);
      if(onJoin.status === "PARTY_NOT_FOUND") {
        alert("Could not find a party with that code!")
      }
      if(onJoin.status === "OK") {
        this.setState({partyCode: partyCode})
      }
    })
    event.preventDefault();
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
        {this.state.isSignedIn === false &&
        <div className="authenticate">
          <p>
            Sign in with Spotify in-order to get started!
          </p>
          <button onClick={this.openSpotifyAuthentication}>Sign in with Spotify</button>
        </div>
        }
        {(this.state.isSignedIn && this.state.partyCode == null) &&
          <div className="party-code">
            <form onSubmit={this.handleSubmit}>
              <label>
                Enter your party code here:
                <input type="text" value={this.state.textFieldContents} onChange={this.handleCodeChange} />
              </label>
              <input type="submit" value="Join"/>
            </form>
          </div>
        }
        {(this.state.partyCode !== null && this.state.isSignedIn) &&
        <div>
          <p>
            In party: {this.state.partyCode}
          </p>
          <PartyController partyCode={this.state.partyCode} authToken={this.state.authToken}/>
        </div>
        }
      </div>
    );
  }
}

export default App;
