import React from 'react';
import './PartyController.css';
import blueButton from './buttons/BlueButton.png';
import greenButton from './buttons/GreenButton.png';
import redButton from './buttons/RedButton.png';
import yellowButton from './buttons/YellowButton.png';

class PartyController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {answeredQuestions: [], currentQuestion: null, currentStatus: 'WAITING_FOR_HOST'}
        this.startRefreshTask = this.startRefreshTask.bind(this);
        this.sendStateRequest = this.sendStateRequest.bind(this);
        this.answerQuestion = this.answerQuestion.bind(this);
        this.startRefreshTask();
    }

    async sendStateRequest(code) {    
        const response = await fetch("https://programmering2.farkasdev.com/party/party_status", {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Authorization': this.props.authToken,
            'short': code
          },
          referrerPolicy: 'origin'
        });
        return response.json();
      }

    startRefreshTask() {
        setTimeout(() => {
            console.log("Delayed for 1 second.");
            this.sendStateRequest(this.props.partyCode).then(partyState => {
                console.log(partyState);
                if(partyState.state == "loading") {
                    this.setState({currentStatus: 'WAITING_FOR_HOST'})
                }
                if(partyState.state == "question") {
                    if((new Date().getTime()/1000 - partyState.expires_at) > 0) {
                        this.setState({currentStatus: 'WAITING_FOR_NEXT'})
                    }
                    else if(this.state.answeredQuestions.indexOf(partyState.current_question_id) >= 0 ) {
                        this.setState({currentStatus: 'WAITING_FOR_NEXT'})
                    }
                    else {
                        this.setState({currentStatus: 'ANSWER', currentQuestion: partyState.current_question_id})
                    }
                }
            })
            this.startRefreshTask();
        }, "3000")
    }

    async answerQuestion(questionId, option) {
        var answered = this.state.answeredQuestions;
        answered.push(questionId);
        this.setState({answeredQuestions: answered, currentStatus: 'WAITING_FOR_NEXT'})
        let formData = new FormData();
        formData.append('question_id', questionId);
        formData.append('option', option);
        const response = await fetch("https://programmering2.farkasdev.com/party/answer", {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Authorization': this.props.authToken,
          },
          referrerPolicy: 'origin',
          body: formData
        });
        return response.json();
    }

    render() {
        return (
            <div>
            {this.state.currentStatus == 'WAITING_FOR_HOST' &&
                <div>
                    <p>Waiting for the host to start the game...</p>
                </div>
            }
            {this.state.currentStatus == 'WAITING_FOR_NEXT' &&
                <div>
                    <p>You've already answered, waiting for next question...</p>
                </div>
            }
            {this.state.currentStatus == 'ANSWER' &&
                <div>
                    <p>Please answer the question you see on screen.</p>
                    <div className={"row"}> 
                        <div class="column">
                            <img src={yellowButton} style={{width: "100%"}} onClick={() => {
                                this.answerQuestion(this.state.currentQuestion, 0)
                            }}/>
                            <img src={redButton} style={{width: "100%"}} onClick={() => {
                                this.answerQuestion(this.state.currentQuestion, 1)
                            }}/>
                        </div>
                        <div className={"column"}>
                            <img src={greenButton} style={{width: "100%"}} onClick={() => {
                                this.answerQuestion(this.state.currentQuestion, 2)
                            }}/>
                            <img src={blueButton} style={{width: "100%"}} onClick={() => {
                                this.answerQuestion(this.state.currentQuestion, 3)
                            }}/>
                        </div>  
                    </div>
                </div>
            }
            </div>
        );
    }
}

export default PartyController;